<template>
  <div class="page">
    <div class="wrapper illustration illustration_form">
      <Backlink title="confirmation" :backlink="backlink" />
      <div class="content">
        <div class="content__container">
          <Changes :recovery="recovery" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Changes from '@/components/profile/Changes.vue'

export default {
  name: 'Registration_page',
  components: { Backlink, Changes },
  props: {
    backlink: { type: String, default: '/' },
    recovery: { type: Boolean, default: false }
  }
}
</script>
